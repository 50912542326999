.carousel-container{
  height: 85vh;
}

.carousel-indicators [data-bs-target] {
  border-radius: 100%;
  width: 10px;
  height: 10px;
}
.carousel-item > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.carousel-item{

}

.buttondiv{
padding:50px;
height: 50px;

}

.sizing{
  width: 100%;
  height: 85vh;
}

.header-caption{
  background-color: rgba(242, 13, 13, 0.3);
  border-radius: 10px;
}

.h3{
  
  text-align: center;
  font-size: 6em;
  -webkit-text-stroke: 1px black;
  color: white;
  text-shadow:
      3px 3px 0 #000,
    -1px -1px 0 #000,  
     1px -1px 0 #000,
     -1px 1px 0 #000,
      1px 1px 0 #000;

}

.p{
  color: #fff;
    text-shadow: 3px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    font-size: 3em;

}

.button{
  padding: 8px 20px;
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #fff;
  color: #242424;
  font-size: 25px;
  margin-right: 50px;
  margin-left: 50px;
  text-decoration: none;
  @media screen and (max-width: 960px) {
    font-size: 17px;
    margin-right: 5px;
  margin-left: 5px;
  }
  &:hover {
    transition: all 0.3s ease-out;
  background: black;
  color: white;
  transition: 250ms;
  }
}

@media screen and (max-width: 960px) {
  .carousel-item{
    height: 30vh;
    
  }
  .buttondiv{
    padding:20px;
  }

  .sizing{
    width: 100%;
    height: auto;
  }
  .h3{
    font-size: 2em;
  }
  .p{
    font-size: 1em;
  }
}
