.modal-container{
    position: fixed;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0,0,0,0.5);
    
}

.modala{
    background-color: white;
    border-radius: 5px;
    padding: 2rem;
    width: 30em;
}
.btn {
    border: none;
    color: black;
    padding: 0.5em 1.5em;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 5px 5px #ccc;
    font-size: 1rem;
}

.h2contact{
  font-size: 2em;
}

.btn-submit{
    background-color: #42a942;
}

.btn-cancel{
    background-color: #d9534f;
}

.close{
    color:black;
    cursor: pointer;
}

.modal-header{
    display: flex;
    justify-content: flex-end;
    font-size: 2rem
}

.modal-content{
    margin-bottom: 2rem;
}

.modal-footer{
    display: flex;
    justify-content: space-evenly;
}

/**/
.contact-form-container {
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
}

.contact-form-container h2 {
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/**/

@media screen and (max-width: 960px) {
    .modal-container{
        padding: 0;
        width: 100%;
    }
    .modala{
    }
    .contact-form-container {
      width: 350px;
    }
}